import * as React from 'react'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Box, Button, Grid, IconButton, TablePagination } from '@mui/material'
import ModalConfirm from '~components/modais/modalConfirm'
import { useUserStore } from 'stores/users'
import ModalHistory from '~components/modais/modalHistory'
import { MoonLoader } from 'react-spinners'
import { getStatusInfo } from 'constants/proceduresConst'
import dayjs from 'dayjs'
import { colors } from '~constants/colorsPallete'
import { Cell, CustomTable, TableMain, Title } from '../conversationsTable/styled'
import xlsx from 'json-as-xlsx'
import { exportObject } from '~utils/transform'
import { GetMedicalProductions } from '~services/MedicalProductions'
import { override } from '../conversationsTable/types'
import { DocumentsTableProps } from './type'
import InfoIcon from '@mui/icons-material/Info'
import ModalProductionDetails from '~components/modais/modalProductionDetails'

export function Money({ value }) {
  const formattedValue = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value / 100)

  return <span>{formattedValue}</span>
}

export default function ProducaoTable({ data, isCom, loading, onFilterChange, count, params }: DocumentsTableProps) {
  const [page, setPage] = React.useState(0)
  const [openHistory, setOpenHistory] = React.useState(false)
  const [openDetails, setOpenDetails] = React.useState(false)
  const [selectedData, seSelectedData] = React.useState()
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  /*   const [newParams, setNewParams] = React.useState(params) */
  const [open, setOpen] = React.useState(false)
  const [exportLoading, setExportLoading] = React.useState(false)
  const [dataHistory, setDataHistory] = React.useState()
  const Uid = useUserStore((state) => state.phone)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)
  const settings = {
    fileName: 'ProducaoMedica',
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleHistoryClose = () => setOpenHistory(false)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const ExportData = () => {
    setExportLoading(true)
    GetMedicalProductions(Uid, access, client, { page: '1', per_page: '5000' }, params)
      .then((response) => {
        response.data.data.map((item: any) => {
          exportObject.content.push({
            medico: item?.attributes?.doctor_name,
            paciente: item?.attributes?.patient,
            convenio: item?.attributes?.insurance_name,
            procedimento: item?.attributes?.procedure_name,
            codigo: item?.attributes?.procedure_code,
            cd_atendimento: item?.attributes?.consultation_code || '--',
            dt_competencia_repasse: item?.attributes?.dt_competencia_repasse
              ? dayjs(item?.attributes?.dt_competencia_repasse, 'YYYY-MM-DD').format('DD/MM/YYYY')
              : '--',
            status: item?.attributes?.institution_status,
            'Data de recebimento do convênio': item.attributes?.insurance_payment_date
              ? dayjs(item?.attributes?.insurance_payment_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
              : '',
            'Data prevista para pagamento': item?.attributes?.insurance_due_date
              ? dayjs(item?.attributes?.insurance_due_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
              : '',
            'Data de lançamento': item?.attributes?.performing_date
              ? dayjs(item?.attributes?.performing_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
              : '',
            faturado: 'R$ ' + item?.attributes?.amount_gross_billed_cents / 100 || 0,
            produzido: 'R$ ' + item?.attributes?.amount_produced_cents / 100 || 0,
            recebido: 'R$ ' + item?.attributes?.amount_paid_by_insurance_cents / 100 || 0,
            repasse: 'R$ ' + item?.attributes?.net_amount_cents / 100 || 0,
            'Motivo da glosa': item?.attributes?.disallowance?.reason || '',
            'Justificativa da glosa': item?.attributes?.disallowance?.observation || '',
            glosa: item?.attributes?.disallowance
              ? 'R$ ' + item?.attributes?.disallowance?.amount_cents / 100
              : 'R$ ' + 0,
          })
        })
        xlsx([exportObject], settings)
        setExportLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setExportLoading(false)
      })
  }

  React.useEffect(() => {
    onFilterChange({ page: page + 1, per_page: rowsPerPage })
  }, [page, rowsPerPage])

  return (
    <Box>
      {loading ? (
        <Box alignContent={'center'} justifyContent={'center'} p={2}>
          <MoonLoader
            color={'#009d81'}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
            cssOverride={override}
          />
        </Box>
      ) : (
        data && (
          <CustomTable>
            <Grid container display={'flex'} justifyContent="space-between" alignContent={'center'}>
              <Title>Todas as Produções</Title>
              <Button
                disabled={exportLoading}
                onClick={() => {
                  ExportData()
                }}
                sx={{ backgroundColor: colors['gray.400'], color: colors.white, height: 40, mr: 2 }}
              >
                {exportLoading ? 'Carregando...' : 'Download XLS'}
              </Button>
            </Grid>

            <TableMain>
              <TableHead>
                <TableRow>
                  <Cell align="center">Médico</Cell>
                  <Cell align="center">Código do procedimento</Cell>
                  <Cell align="center">Nome do procedimento</Cell>
                  <Cell align="center">Data de realização</Cell>
                  <Cell align="center">Competência repasse</Cell>
                  <Cell align="center">Convênio</Cell>
                  <Cell align="center">Número atendimento</Cell>
                  <Cell align="center">Nome do paciente</Cell>
                  <Cell align="center">Detalhes</Cell>
                  <Cell align="center">Status</Cell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row: any, index: number) => {
                  const { bgcolor, text } = getStatusInfo(row?.attributes?.institution_status)

                  return (
                    <TableRow key={index} hover>
                      <TableCell component="th" scope="row" align="center">
                        {row?.attributes?.doctor_name || 'Não informado'}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {row?.attributes?.procedure_code}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {row?.attributes?.procedure_name}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {dayjs(row?.attributes?.performing_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {row?.attributes?.dt_competencia_repasse
                          ? dayjs(row?.attributes?.dt_competencia_repasse, 'YYYY-MM-DD').format('DD/MM/YYYY')
                          : '--'}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {row?.attributes?.insurance_name}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {row?.attributes?.consultation_code || '--'}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {row?.attributes?.patient}
                      </TableCell>
                      {/* <TableCell component="th" scope="row" align="center">
                        {row?.attributes?.transaction?.amount_cents
                          ? Money({ value: row?.attributes?.transaction?.amount_cents })
                          : '--'}
                      </TableCell> */}
                      <TableCell
                        align="center"
                        onClick={() => {
                          seSelectedData(row)
                          setOpenDetails(true)
                        }}
                      >
                        <IconButton aria-label="delete">
                          <InfoIcon fontSize="medium" sx={{ color: colors.blue }} />
                        </IconButton>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Box
                          minWidth={110}
                          bgcolor={bgcolor}
                          color="white"
                          borderRadius={100}
                          textAlign="center"
                          py="2px"
                          display={'inline-block'}
                        >
                          {text}
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </TableMain>

            <TablePagination
              labelRowsPerPage="Itens por página"
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CustomTable>
        )
      )}
      <ModalHistory handleClose={() => handleHistoryClose()} open={openHistory} data={dataHistory} />
      <ModalProductionDetails handleClose={() => setOpenDetails(false)} open={openDetails} data={selectedData} />
      <ModalConfirm handleOpen={handleOpen} handleClose={handleClose} open={open} />
    </Box>
  )
}
