export const colors = {
  'green.default': '#009D81',
  'green.light': '#CEFF7E',
  blue: '#414AA1',
  'blue.light': '#4972DB',
  cyan: '#00E5E5',
  'green.600': '#067863',
  warn: '#E4BE34',
  error: '#E96338',
  success: '#24B779',
  white: '#FFFFFF',
  bg: '#F2F2F2',
  'white.200': '#F3EFE8',
  'white.300': '#E8E3DB',
  'gray.100': '#E3E2EF',
  'gray.200': '#BCBBD0',
  'gray.300': '#9998BC',
  'gray.400': '#706E90',
  'gray.500': '#4B4A64',
  'gray.600': '#2B2A3D',
}
