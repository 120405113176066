import { Box, Grid } from '@mui/material'

import { ToastContainer, toast } from 'react-toastify'
import RatingTable from '~components/table/ratingTable'
import React, { useEffect } from 'react'
import { useUserStore } from 'stores/users'
import { LoadingIndicator } from '~components/loading'
import { GetUserScores } from '~services/Home'
import MiniDrawer from '~components/drawer'
import { colors } from '~constants/colorsPallete'
import StarIcon from '@mui/icons-material/Star'
import Header from '~components/header'
import { SendSlackMessage } from '~services/Slack'
import NoData from '~containers/noData'
import { useLocation, useNavigate } from 'react-router-dom'

export type Score = {
  id: string
  type: string
  attributes: {
    rating: number
    created_at: string
    user_hashid: string
    user_name: string
    data_source: string
  }
}

function RatingPage() {
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState<any>([])
  const [allData, setAllData] = React.useState<any>()
  const Uid = useUserStore((state) => state.phone)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)
  const { openDrawer } = useUserStore()
  const location = useLocation()
  const navigate = useNavigate()
  const from = location.state?.from?.pathname || '/'
  const clearUser = useUserStore((state) => state.clearUser)
  const loggedIn = useUserStore((state) => state.setLogged)

  const filterLatestRatings = (data: Score[]): Score[] => {
    const latestRatings: { [key: string]: Score } = {}

    data.forEach((score) => {
      const userHashId = score.attributes.user_hashid
      const createdAt = score.attributes.created_at

      if (!latestRatings[userHashId] || createdAt > latestRatings[userHashId].attributes.created_at) {
        latestRatings[userHashId] = score
      }
    })

    const sortedData = Object.values(latestRatings).sort((a, b) => a.attributes.rating - b.attributes.rating)

    return sortedData
  }

  useEffect(() => {
    setLoading(true)

    GetUserScores(Uid, access, client)
      .then((response) => {
        setAllData(response.data.data)
        const filteredData = filterLatestRatings(response.data.data)
        setData(filteredData)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        if (error.request.status === 401) {
          loggedIn(false)
          clearUser()
          navigate(from, { replace: true })
        }
        toast.error('Ooops! Algo deu errado, tente novamente!')
        SendSlackMessage('/shared_api/v1/scores?per_page=1000?order_by=asc', error, null, Uid, 'Get')
        console.log(error)
      })
  }, [])

  return (
    <>
      <Box sx={{ backgroundColor: colors.bg }}>
        <ToastContainer />
        <Grid container spacing={2} p={2} columns={16}>
          <Grid item xs={2} sm={openDrawer ? 3 : 1} md={openDrawer ? 3 : 1}>
            <MiniDrawer />
          </Grid>
          <Grid item xs={14} sm={openDrawer ? 13 : 15} md={openDrawer ? 13 : 15}>
            <Header text="Avaliações" icon={<StarIcon sx={{ mt: '4px', mr: 2, color: colors['gray.500'] }} />} />
            {loading ? (
              <LoadingIndicator />
            ) : data?.length === 0 ? (
              <NoData />
            ) : (
              <RatingTable data={data} allData={allData} />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default RatingPage
