import { ApiVersion, BaseService } from '~services/api'

export const GetInsurances = (Uid: string, access: string, client: string) => {
  return BaseService.get(`/institution_api/v1/insurances?per_page=100`, {
    timeout: 1000000,
    headers: {
      'Content-Type': 'application/json',
      Client: client,
      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}

export const GetAllProcedureCodes = (Uid: string, access: string, client: string) => {
  return BaseService.get(`/institution_api/v1/procedures`, {
    timeout: 1000000,
    headers: {
      'Content-Type': 'application/json',
      Client: client,
      APP_VERSION: ApiVersion,
      Uid: Uid,
      'Access-Token': access,
    },
  })
}
