import { Box, Grid } from '@mui/material'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import Header from '~components/header'
import ModalNewComunicacao from '~components/modais/modalNewComunicacao'
import { useUserStore } from 'stores/users'
import ConversationTable from '~components/table/conversationsTable'
import { GetAllConversations } from '~services/Conversations'
import MiniDrawer from '~components/drawer'
import { colors } from '~constants/colorsPallete'
import { SendSlackMessage } from '~services/Slack'
import NoData from '~containers/noData'
import { Attributes } from '~components/table/conversationsTable/types'
import ConversationFilters from './filters'
import { useLocation, useNavigate } from 'react-router-dom'

function Comunicacao() {
  const [pageInfo, setPageInfo] = useState({ page: '1', per_page: '10' })
  const setNotificationMessageToken = useUserStore((state) => state.setNotificationMessageToken)
  const [openNewComunicacao, setOpenNewComunicacao] = React.useState(false)
  const [count, setCount] = React.useState<number>(0)
  const [filterMedicOptions, setFilterMedicOptions] = React.useState<any>([])
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState<Attributes[]>()
  const Uid = useUserStore((state) => state.phone)
  const filter = useUserStore((state) => state.filter)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)
  const location = useLocation()
  const navigate = useNavigate()
  const from = location.state?.from?.pathname || '/'
  const clearUser = useUserStore((state) => state.clearUser)
  const loggedIn = useUserStore((state) => state.setLogged)
  const { openDrawer } = useUserStore()
  const params: Record<string, string> = {}
  const [filters, setFilters] = useState<{
    doctor: string
    date: string
    status: string
    conversations: string
    selectedsubject: string
    filter: string
    statusProducao: string
  }>({
    doctor: '',
    date: '',
    status: '',
    conversations: '',
    selectedsubject: '',
    filter: '',
    statusProducao: '',
  })

  const fetchData = (pageInfo: { per_page: string; page: string }) => {
    setLoading(true)
    if (filters.doctor) {
      params['doctor_names[]'] = filters.doctor
    }

    if (filter !== '') {
      params['institution_status'] = filter
    }

    if (filters.statusProducao) {
      params['institution_status'] = filters.statusProducao
    }

    if (filters.status) {
      params['status'] = filters.status
    }

    if (filters.conversations) {
      params['conversations'] = filters.conversations
    }

    if (filters.selectedsubject) {
      params['conversation_subject_hashid'] = filters.selectedsubject
    }

    if (filters.date) {
      const date = filters.date
      const Newdate = new Date(date)

      // Add one day
      Newdate.setDate(Newdate.getDate() + 1)
      const year = Newdate.getFullYear()
      const month = (Newdate.getMonth() + 1).toString().padStart(2, '0')
      const day = Newdate.getDate().toString().padStart(2, '0')
      params['between_search_keys[]'] = 'created_at'
      params['between_search_values[]'] = filters.date
      params['between_search_values[] '] = `${year}/${month}/${day}`
    }

    GetAllConversations(Uid, access, client, pageInfo, params)
      .then((response) => {
        setData(response.data.data)
        setCount(response.data.meta.count)
        setNotificationMessageToken(null)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        if (error.request.status === 401) {
          loggedIn(false)
          clearUser()
          navigate(from, { replace: true })
        }
        toast.error('Ooops! Algo deu errado, tente novamente!')
        SendSlackMessage(
          `/shared_api/v1/conversations?per_page=${pageInfo.per_page}&page=${pageInfo.page}`,
          error,
          params,
          Uid,
          'Get',
        )
        console.log(error)
      })
  }

  useEffect(() => {
    fetchData(pageInfo)
  }, [filters])

  const handleFilterChange = (pageInfo: { page: string; per_page: string }) => {
    fetchData(pageInfo)
  }

  const handleFilterChanges = (newFilters: {
    doctor: string
    date: string
    status: string
    conversations: string
    selectedsubject: string
    filter: string
    statusProducao: string
  }) => {
    setFilters(newFilters)
  }

  return (
    <>
      <ModalNewComunicacao
        handleClose={() => setOpenNewComunicacao(false)}
        open={openNewComunicacao}
        doctors={filterMedicOptions}
      />
      <Box sx={{ backgroundColor: colors.bg }}>
        <ToastContainer />
        <Grid container spacing={2} p={2} columns={16}>
          <Grid item xs={2} sm={openDrawer ? 3 : 1} md={openDrawer ? 3 : 1}>
            <MiniDrawer />
          </Grid>
          <Grid item xs={14} sm={openDrawer ? 13 : 15} md={openDrawer ? 13 : 15}>
            <Header
              text="Chamados"
              icon={<QuestionAnswerIcon sx={{ mt: '4px', mr: 2, color: colors['gray.500'] }} />}
            />
            <ConversationFilters onFilterChange={handleFilterChanges} />
            {data?.length === 0 && !loading ? (
              <NoData />
            ) : (
              <ConversationTable
                data={data}
                isCom={true}
                loading={loading}
                onFilterChange={handleFilterChange}
                count={count}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Comunicacao
