export const exportObject = {
  sheet: 'MedicalProduction',
  columns: [
    { label: 'MÉDICO', value: 'medico' },
    { label: 'PACIENTE', value: 'paciente' },
    { label: 'CONVÊNIO', value: 'convenio' },
    { label: 'CÓDIGO DO PROCEDIMENTO', value: 'codigo' },
    { label: 'NUMERO ATENDIMENTO', value: 'cd_atendimento' },
    { label: 'PROCEDIMENTO', value: 'procedimento' },
    { label: 'STATUS', value: 'status' },
    { label: 'COMPETENCIA DO REPASSE', value: 'dt_competencia_repasse' },
    { label: 'DATA DE RECEBIMENTO DO CONVÊNIO', value: 'Data de recebimento do convênio' },
    { label: 'DATA PREVISTA PARA PAGAMENTO', value: 'Data prevista para pagamento' },
    { label: 'DATA DE LANÇAMENTO', value: 'Data de lançamento' },
    { label: 'VALOR PRODUZIDO', value: 'produzido' },
    { label: 'VALOR FATURADO', value: 'faturado' },
    { label: 'VALOR RECEBIDO', value: 'recebido' },
    { label: 'VALOR REPASSADO', value: 'repasse' },
    { label: 'MOTIVO DA GLOSA', value: 'Motivo da glosa' },
    { label: 'JUSTIFICATIVA DA GLOSA', value: 'Justificativa da glosa' },
    { label: 'VALOR DA GLOSA', value: 'glosa' },
  ],

  content: [],
}

export const DisallowanceObject = {
  sheet: 'Glosas',
  columns: [
    { label: 'DATA GLOSA', value: 'dt_glosa' },
    { label: 'MOTIVO GLOSA', value: 'motivo_glosa' },
    { label: 'SITUAÇÃO', value: 'situacao' },
    { label: 'PACIENTE', value: 'nome_paciente' },
    { label: 'MÉDICO', value: 'nm_medico' },
    { label: 'CONVÊNIO', value: 'convenio' },
    { label: 'CÓDIGO DO PROCEDIMENTO', value: 'cd_procedimento' },
    { label: 'PROCEDIMENTO', value: 'nm_procedimento' },
    { label: 'VALOR GLOSA', value: 'valor_glosa' },
    { label: 'VALOR COBRADO', value: 'valor_cobrado' },
  ],

  content: [],
}

export const TransactionsObject = {
  sheet: 'Transactions',
  columns: [
    { label: 'DATA DE REPASSE', value: 'dt_repasse' },
    { label: 'PROCEDIMENTO', value: 'procedimento' },
    { label: 'PACIENTE', value: 'paciente' },
    { label: 'CONVÊNIO', value: 'convenio' },
    { label: 'DATA DE REALIZAÇÃO', value: 'dt_realizacao' },
    { label: 'CÓDIGO DO PROCEDIMENTO', value: 'cd_procedimento' },
    { label: 'VALOR', value: 'valor' },
  ],

  content: [],
}

export const CompanyObject = {
  sheet: 'Empresas',
  columns: [
    { label: 'RAZAO SOCIAL', value: 'name' },
    { label: 'MEDICO(A)', value: 'user' },
    { label: 'CNPJ', value: 'cpf_cnpj' },
    { label: 'EMAIL', value: 'email' },
    { label: 'REGISTRO ESTADUAL', value: 'state_registration' },
    { label: 'REGISTRO MUNICIPAL', value: 'municipal_registration' },
    { label: 'TELEFONE', value: 'phone' },
    { label: 'WEBSITE', value: 'website' },
  ],

  content: [],
}
