import { Box, Button, Grid } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import AllCompaniesTable from '~components/table/allCompaniesTable'
import { CustomButton } from '~utils/styles'
import ModalAddNewNfs from '~components/modais/modalAddNewNfs'
import React, { useState } from 'react'
import { useUserStore } from 'stores/users'
import { ToastContainer, toast } from 'react-toastify'
import MiniDrawer from '~components/drawer'
import { colors } from '~constants/colorsPallete'
import WorkIcon from '@mui/icons-material/Work'
import Header from '~components/header'
import { SendSlackMessage } from '~services/Slack'
import { GetUsers } from '~services/User'
import { GetAllCompanies, GetAllCompaniesFull } from '~services/Companies'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import xlsx from 'json-as-xlsx'
import { CompanyObject } from '~utils/transform'

function AllDoctors() {
  const [isCreate, setIsCreate] = React.useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const Uid = useUserStore((state) => state.phone)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)
  const [filterMedicOptions, setFilterMedicOptions] = useState<any>()
  const [data, setData] = useState<[]>([])
  const { openDrawer } = useUserStore()
  const [count, setCount] = React.useState<number>(0)
  const [companiesFull, setCompaniesFull] = React.useState<any>()
  const [exportLoading, setExportLoading] = React.useState(false)
  const settingsCompanies = {
    fileName: 'Empresas',
  }

  const handleFilterChange = (pageInfo: { page: string; per_page: string }) => {
    setLoading(true)

    GetUsers(Uid, access, client)
      .then((response) => {
        setFilterMedicOptions(response.data.data)
      })
      .catch((error) => {
        console.log(error)
      })

    GetAllCompaniesFull(Uid, access, client).then((response) => {
      setCompaniesFull(response.data.data)
    })

    GetAllCompanies(Uid, access, client, pageInfo)
      .then((response) => {
        setCount(response.data.meta.count)
        setData(response.data.data)

        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Ooops! Algo deu errado, tente novamente!')
        SendSlackMessage(
          `/institution_api/v1/companies/?per_page=${pageInfo.per_page}&page=${pageInfo.page}`,
          error,
          null,
          Uid,
          'Get',
        )
      })
  }

  const exportData = () => {
    setExportLoading(true)
    companiesFull.map((item: any) => {
      CompanyObject.content.push({
        name: item.attributes?.name,
        user: item.attributes?.users[0].name,
        cpf_cnpj: item.attributes?.cpf_cnpj,
        email: item.attributes?.email,
        state_registration: item.attributes?.state_registration,
        municipal_registration: item.attributes.municipal_registration,
        phone: item.attributes?.phone,
        website: item.attributes?.website,
      })
    })
    xlsx([CompanyObject], settingsCompanies)
    setExportLoading(false)
  }

  return (
    <>
      <ModalAddNewNfs
        isCreate={isCreate}
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
        isDoc={false}
        doctors={companiesFull}
        users={filterMedicOptions}
      />
      <Box sx={{ backgroundColor: colors.bg }}>
        <ToastContainer />
        <Grid container spacing={2} p={2} columns={16}>
          <Grid item xs={2} sm={openDrawer ? 3 : 1} md={openDrawer ? 3 : 1}>
            <MiniDrawer />
          </Grid>
          <Grid item xs={14} sm={openDrawer ? 13 : 15} md={openDrawer ? 13 : 15}>
            <Header text="Empresas" icon={<WorkIcon sx={{ mt: '4px', mr: 2, color: colors['gray.500'] }} />} />

            <Grid item xs={16} display={'flex'} alignContent={'flex-end'} justifyContent={'flex-end'} gap={1} mt={4}>
              <CustomButton
                onClick={() => {
                  setIsCreate(false)
                  handleOpen()
                }}
                startIcon={<AddIcon />}
              >
                Solicitar Emissão de Nota Fiscal
              </CustomButton>
              <Button
                startIcon={<DownloadForOfflineIcon />}
                disabled={exportLoading}
                onClick={() => {
                  exportData()
                }}
                sx={{ backgroundColor: colors['gray.400'], color: colors.white, height: '40px', px: 2 }}
              >
                {exportLoading ? 'Carregando...' : 'Download Relatorio'}
              </Button>
            </Grid>
            <AllCompaniesTable data={data} loading={loading} onFilterChange={handleFilterChange} count={count} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default AllDoctors
