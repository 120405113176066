import React, { useState } from 'react'
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { colors } from '~constants/colorsPallete'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'

interface ReceiptsFiltersProps {
  onFilterChange: (filters: any) => void
}

const ReceiptsFilters: React.FC<ReceiptsFiltersProps> = ({ onFilterChange }) => {
  const [date, setDate] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [endDate, setEndDate] = useState<string>('')
  const [status, setStatus] = React.useState<string>(null)

  const clearFilters = () => {
    // Reset filter states
    setStatus(null)
    setDate('')
    setEndDate('')

    onFilterChange({
      date: '',
      endDate: '',
      status: '',
    })
  }

  const handleChangeStatus = (event) => {
    setStatus(event.target.value)
  }

  return !isOpen ? (
    <Grid container direction="row" justifyContent="flex-end" mb={2}>
      <Button
        disableElevation
        variant="contained"
        endIcon={<FilterAltRoundedIcon />}
        sx={{ backgroundColor: colors['gray.500'], borderRadius: 100 }}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        Abrir Filtros
      </Button>
    </Grid>
  ) : (
    <>
      <Grid container direction="row" justifyContent="flex-end" mb={2}>
        <Grid item xs={12} display={'flex'} justifyContent={'flex-end'} mb={0} pb={0}>
          <Button
            disableElevation
            variant="contained"
            endIcon={<FilterAltRoundedIcon />}
            sx={{ backgroundColor: colors['gray.500'], borderRadius: 100 }}
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            Fechar Filtros
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={1}
        p={3}
        mb={2}
        border={'1px solid #E0E0E0'}
        borderRadius={'3px'}
        sx={{ backgroundColor: colors['white.300'] }}
      >
        <Grid item xl={2} sm={5} md={4} lg={2} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
            <FormControl fullWidth>
              <DatePicker
                label="Início do Período de Recebimento"
                format="DD/MM/YYYY"
                defaultValue={dayjs().startOf('month')}
                sx={{
                  backgroundColor: colors.white,
                  borderRadius: 100,
                  border: 0,
                  '& fieldset': { border: 'none' },
                }}
                onChange={(e) => {
                  setDate(dayjs(e).format('YYYY/MM/DD'))
                }}
              />
            </FormControl>
          </LocalizationProvider>
        </Grid>
        <Grid item xl={2} sm={5} md={4} lg={2} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
            <FormControl fullWidth>
              <DatePicker
                label="Fim do Período de Recebimento"
                format="DD/MM/YYYY"
                minDate={dayjs(date)}
                defaultValue={dayjs()}
                sx={{
                  backgroundColor: colors.white,
                  borderRadius: 100,
                  border: 0,
                  '& fieldset': { border: 'none' },
                }}
                onChange={(e) => {
                  setEndDate(dayjs(e).format('YYYY/MM/DD'))
                }}
              />
            </FormControl>
          </LocalizationProvider>
        </Grid>
        <Grid item xl={2} sm={5} md={4} lg={2} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              id="demo-customized-select-native"
              value={status}
              sx={{
                backgroundColor: colors.white,
                borderRadius: 100,
                border: 0,
                '& fieldset': { border: 'none' },
              }}
              onChange={handleChangeStatus}
            >
              <MenuItem value={''}>Todas</MenuItem>
              <MenuItem value={'solicited'}>Solicitado</MenuItem>
              <MenuItem value={'received'}>Recebido</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xl={12} sm={12} md={12} lg={12} xs={12}>
          <Box display="flex" flexDirection="row" alignContent={'center'} justifyContent={'flex-end'} mt={3}>
            <Button
              disableElevation
              variant="contained"
              sx={{ backgroundColor: colors.success, borderRadius: 100, mr: 2 }}
              onClick={() => {
                onFilterChange({ date, status, endDate })
              }}
            >
              Filtrar
            </Button>
            <Button
              disableElevation
              variant="outlined"
              sx={{ borderColor: colors.error, color: colors.error, borderRadius: 100 }}
              onClick={clearFilters}
            >
              Limpar filtros
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default ReceiptsFilters
