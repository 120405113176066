import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Autocomplete, Checkbox, Chip, Grid, Input, InputAdornment, OutlinedInput, TextField } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import ModalConfirmDoc from '../modalConfirmDoc'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import dayjs from 'dayjs'
import { ToastContainer, toast } from 'react-toastify'
import { useUserStore } from 'stores/users'
import { useDropzone } from 'react-dropzone'
import { Container, ContainerNew } from '~components/dropzone'
import 'dayjs/locale/pt-br'
import { uploadFile } from '~services/S3'
import { SendSlackMessage } from '~services/Slack'
import { CreateNfs, InvoiceNotify } from '~services/Receipts'
import { CompanySchema } from 'schemas'
import { style } from './styled'
import { checkedIcon, icon } from '~utils/styles'
import { colors } from '~constants/colorsPallete'
import { CheckOutlined, CloseOutlined } from '@mui/icons-material'

export default function ModalAddNewNfs({
  handleClose,
  open,
  isDoc,
  doctors,
  users,
  isCreate,
}: {
  handleOpen: VoidFunction
  handleClose: VoidFunction
  isDoc: boolean
  open: boolean
  users?: any
  isCreate: boolean
  doctors?: any
}) {
  const [openSucess, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [date, setDate] = React.useState('')
  const setNfData = useUserStore((state) => state.setNfData)
  const nfData = useUserStore((state) => state.nfData)
  const [selectedDoctor, setSelectedDoctor] = React.useState<any>('')
  const [acceptedFile, setAcceptedFile] = React.useState<any>()
  const Uid = useUserStore((state) => state.phone)
  const id = useUserStore((state) => state.id)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)
  const [searchQuery, setSearchQuery] = useState('')
  const [netAmount, setNetAmount] = useState('')
  const [filteredDoctors, setFilteredDoctors] = useState(doctors)

  useEffect(() => {
    setFilteredDoctors(
      doctors?.filter((doctor: any) => doctor.attributes.name.toLowerCase().includes(searchQuery.toLowerCase())),
    )
  }, [searchQuery, doctors])

  const handleOpen = () => {
    handleClose()
    setOpen(true)
  }
  const handleDismiss = () => setOpen(false)

  const formik = useFormik({
    initialValues: {
      cnpj: '',
      invoiceNumber: '',
      fantasia: '',
    },
    validationSchema: CompanySchema,

    onSubmit: () => {
      setLoading(true)
      uploadFile(acceptedFile, Uid, access, client).then((res) => {
        const data = {
          corporate_name: formik.values.fantasia,
          invoice_date: date,
          pdf_file_url: res,
          institution_user_id: id,
          received_status: 1,
          verification_status: 0,
          cnpj: formik.values.cnpj,
          invoice_number: formik.values.invoiceNumber,
          user_id: selectedDoctor.id,
        }
        CreateNfs(Uid, access, client, data)
          .then(() => {
            toast.success('Nota fiscal cadastrada com sucesso!')
            setLoading(false)
            setTimeout(() => window.location.reload(), 3000)
          })
          .catch((error) => {
            console.log(error)
            SendSlackMessage(
              `/shared_api/v1/invoices`,
              error,
              {
                invoice: data,
              },
              Uid,
              'Post',
            )
            setLoading(false)
            toast.error('Ooops, algo deu errado! Tente novamente.')
          })
      })
    },
  })

  const handleSend = () => {
    setLoading(true)
    uploadFile(acceptedFile, Uid, access, client).then((res) => {
      InvoiceNotify(Uid, access, client, nfData, res, netAmount)
        .then(() => {
          toast.success('Solicitação realizada com sucesso!')
          setLoading(false)
          setTimeout(() => window.location.reload(), 3000)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
          toast.error('Ooops, algo deu errado! Tente novamente.')
        })
    })
  }

  const { errors, touched, handleSubmit, getFieldProps } = formik

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setAcceptedFile(acceptedFiles[0])
    },
  })

  const acceptedFileItems = acceptedFiles.map((file: any) => <li key={file.path}>{file.path}</li>)

  const handleSelectAll = (isSelected: boolean) => {
    if (!isSelected) {
      setNfData(doctors.map((doctor: any) => doctor.id))
    } else {
      setNfData([])
    }
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <ModalConfirmDoc handleOpen={handleOpen} handleClose={handleDismiss} open={openSucess} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box sx={style} width={'70%'}>
              <Typography variant="h5" component="h5" sx={{ fontWeight: 800 }}>
                {isCreate ? 'Anexar nova Nota Fiscal' : 'Solicitar envio de nota fiscal'}
              </Typography>
              {isCreate ? (
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div className="container">
                      <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
                        <input {...getInputProps()} />
                        <p>Arraste ou clique para adicionar novos arquivos</p>
                      </Container>
                      <aside>
                        {acceptedFiles.length > 0 ? (
                          <>
                            <Typography variant="h6" mt={4}>
                              Nota adicionada
                            </Typography>
                            <ul>{acceptedFileItems}</ul>{' '}
                          </>
                        ) : null}
                      </aside>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box mt={3} display={'flex'} flexDirection={'column'} gap={3}>
                      <TextField
                        fullWidth
                        autoComplete="Nome Fantasia"
                        type="text"
                        label="Nome Fantasia"
                        {...getFieldProps('fantasia')}
                        error={Boolean(touched.fantasia && errors.fantasia)}
                        helperText={touched.fantasia && errors.fantasia}
                      />
                      <TextField
                        fullWidth
                        autoComplete="CNPJ"
                        type="number"
                        label="CNPJ"
                        {...getFieldProps('cnpj')}
                        error={Boolean(touched.cnpj && errors.cnpj)}
                        helperText={touched.cnpj && errors.cnpj}
                      />
                      <TextField
                        fullWidth
                        autoComplete="invoiceNumber"
                        type="text"
                        label="Número da nota fiscal"
                        {...getFieldProps('invoiceNumber')}
                        error={Boolean(touched.invoiceNumber && errors.invoiceNumber)}
                        helperText={touched.invoiceNumber && errors.invoiceNumber}
                      />
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            sx={{ width: '100%' }}
                            label="Data de realização"
                            format="DD/MM/YYYY"
                            defaultValue={dayjs()}
                            onChange={(e) => {
                              setDate(dayjs(e).format('DD/MM/YYYY'))
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      <Autocomplete
                        id="checkboxes-tags-demo"
                        options={doctors}
                        onChange={(event: any, newValue: any) => {
                          setSelectedDoctor(newValue)
                        }}
                        getOptionLabel={(option: { attributes: { name: string } }) => option.attributes.name}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              value={option.attributes.name}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.attributes.name}
                          </li>
                        )}
                        style={{ width: '100%' }}
                        renderInput={(params: any) => (
                          <TextField {...params} label="A quem a Nota Fiscal pertence" placeholder="Médicos" />
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} my={1}>
                    <Typography variant="subtitle1" mb={4} lineHeight={1.4}>
                      Selecione a empresa abaixo em que deseja fazer a solicitação
                    </Typography>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={5} sm={5} md={5} lg={5} my={1}>
                      <div className="container">
                        <ContainerNew {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
                          <input {...getInputProps()} />

                          {acceptedFiles.length > 0 ? (
                            <>
                              <ul>{acceptedFileItems}</ul>
                            </>
                          ) : (
                            <p>Adicionar anexo da solicitação</p>
                          )}
                        </ContainerNew>
                      </div>
                      <OutlinedInput
                        id="pesquisa"
                        placeholder="Valor da solicitação"
                        type="number"
                        value={netAmount}
                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                        onChange={(e) => setNetAmount(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={7} sm={7} md={7} lg={7} my={1}>
                      <Box display="flex" flexDirection={'row'} alignItems={'center'} gap={1} mb={2}>
                        <Box onClick={() => handleSelectAll(nfData?.length === doctors?.length)}>
                          <Chip
                            icon={
                              nfData?.length === doctors?.length ? (
                                <CloseOutlined fontSize="small" />
                              ) : (
                                <CheckOutlined fontSize="small" />
                              )
                            }
                            label="Selecionar todos"
                            color="primary"
                            variant="outlined"
                            sx={{
                              borderColor: nfData?.length === doctors?.length ? colors.success : colors['gray.300'],
                              backgroundColor: nfData?.length === doctors?.length ? colors.success : colors['gray.300'],
                              color: 'white',
                              '&:hover': {
                                backgroundColor: colors['green.default'],
                              },
                            }}
                          />
                        </Box>

                        <TextField
                          id="pesquisa"
                          label="Pesquisar por nome..."
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          fullWidth
                          sx={{
                            backgroundColor: colors['white.200'],
                            borderRadius: 100,
                            border: 0,
                            '& fieldset': { border: 'none' },
                          }}
                        />
                      </Box>
                      <Box height={'350px'} overflow={'auto'}>
                        {filteredDoctors?.map((item: any) => (
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            border={'1px solid #949494'}
                            borderRadius={2}
                            p={1}
                            key={item.id}
                            mb={1}
                            sx={{
                              '&:hover': {
                                backgroundColor: '#f0f0f0',
                              },
                            }}
                          >
                            <Checkbox
                              checked={nfData?.includes(item.id)}
                              onChange={() => {
                                if (Array.isArray(nfData)) {
                                  if (nfData.includes(item.id)) {
                                    setNfData(nfData?.filter((doctor: any) => doctor !== item.id))
                                  } else {
                                    setNfData([...nfData, item.id])
                                  }
                                }
                              }}
                            />
                            <Typography variant="body2" fontWeight={500}>
                              {item.attributes.name}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Box marginTop={'20px'} justifyContent={'flex-end'} display={'flex'} gap={1}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleClose()
                  }}
                  sx={{ borderColor: '#2B2A3D', color: '#2B2A3D', px: 4 }}
                >
                  Fechar
                </Button>
                {isCreate ? (
                  <Button
                    type="submit"
                    variant="contained"
                    disableElevation
                    disabled={loading || !acceptedFile || !selectedDoctor || !date || !formik.isValid}
                    sx={{ color: 'white', backgroundColor: '#009D81', px: 4 }}
                  >
                    {loading ? 'Enviando...' : 'Enviar'}
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      handleSend()
                    }}
                    disableElevation
                    variant="contained"
                    disabled={loading}
                    sx={{ color: 'white', backgroundColor: '#009D81', px: 4 }}
                  >
                    {loading ? 'Enviando...' : 'Enviar'}
                  </Button>
                )}
              </Box>
            </Box>
          </Form>
        </FormikProvider>
      </Modal>
    </>
  )
}
