import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Grid } from '@mui/material'
import NotificationCard from '~components/homeCards/notificationCard'
import React from 'react'
import ModalResponseComunicacao from '../modalResponseComunicacao'
import dayjs from 'dayjs'
import { style } from './styled'

export default function ModalHistory({
  handleClose,
  open,
  data,
  updateData,
}: {
  handleClose: any
  open: boolean
  data: any
  updateData?: () => void
}) {
  const [openNewComunicacao, setOpenNewComunicacao] = React.useState(false)

  const NumeroConta = data?.attributes.medical_production?.data.attributes?.source_identifier.split('--')

  return (
    <>
      <ModalResponseComunicacao
        updateData={updateData}
        open={openNewComunicacao}
        handleClose={() => setOpenNewComunicacao(false)}
        data={data}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h4" component="h2" mb={4}>
            Histórico da tratativa
          </Typography>
          <Box display={'flex'} flexDirection={'row'} width={'100%'}>
            <Box width={'70%'}>
              <Typography variant="body2" component="h2" fontWeight={700}>
                Paciente:
              </Typography>
              <Typography variant="body1" component="h2" mb={1}>
                {data?.attributes?.patient || 'Não informado'}
              </Typography>
              <Typography variant="body2" component="h2" fontWeight={700}>
                Procedimento:
              </Typography>
              <Typography variant="body1" component="h2" mb={1}>
                {data?.attributes?.procedure || 'Não informado'}
              </Typography>
              {data?.attributes.medical_production && (
                <>
                  <Typography variant="body2" component="h2" fontWeight={700}>
                    Médico(a):
                  </Typography>
                  <Typography variant="body1" component="h2" mb={1}>
                    {data?.attributes.medical_production?.data.attributes?.doctor_name || 'Não informado'}
                  </Typography>
                  <Typography variant="body2" component="h2" fontWeight={700}>
                    Data de realização:
                  </Typography>
                  <Typography variant="body1" component="h2" mb={1}>
                    {dayjs(data.attributes.medical_production?.data.attributes?.performing_date, 'YYYY-MM-DD').format(
                      'DD/MM/YYYY',
                    ) || 'Não informado'}
                  </Typography>
                  <Typography variant="body2" component="h2" fontWeight={700}>
                    Status do procedimento:
                  </Typography>
                  <Typography variant="body1" component="h2" mb={1}>
                    {data?.attributes.medical_production?.data.attributes?.institution_status || 'Não informado'}
                  </Typography>
                  <Typography variant="body2" component="h2" fontWeight={700}>
                    Nº do Atendimento:
                  </Typography>
                  <Typography variant="body1" component="h2" mb={1}>
                    {data?.attributes.medical_production?.data.attributes?.consultation_code || 'Não informado'}
                  </Typography>
                  <Typography variant="body2" component="h2" fontWeight={700}>
                    Nº da Conta:
                  </Typography>
                  <Typography variant="body1" component="h2" mb={1}>
                    {NumeroConta[1] || 'Não informado'}
                  </Typography>
                </>
              )}
            </Box>
            <Box width={'30%'} justifyContent={'flex-end'} display={'flex'}>
              <Box
                bgcolor={data?.attributes.status === 'open' ? '#24B779' : '#E96338'}
                color="white"
                width={'fit-content'}
                height={28}
                alignSelf={'start'}
                alignContent={'center'}
                justifyContent={'center'}
                fontSize={18}
                borderRadius={100}
                textAlign="center"
                px={3}
              >
                {data?.attributes.status === 'open'
                  ? 'Aberta'
                  : data?.attributes.status === 'closed'
                  ? 'Fechada'
                  : 'Pendente'}
              </Box>
            </Box>
          </Box>

          <Grid container justifyContent={'end'}>
            {data?.attributes?.messages.length > 0 ? (
              <NotificationCard data={data?.attributes?.messages} />
            ) : (
              <Box
                height={100}
                display={'flex'}
                width={'100%'}
                alignItems={'center'}
                textAlign={'center'}
                justifyContent={'center'}
              >
                <Typography textAlign={'center'} variant="body1">
                  Sem mensagens para essa conversa.
                </Typography>
              </Box>
            )}
          </Grid>
          <div
            style={{
              marginTop: '20px',
              justifyContent: 'flex-end',
              display: 'flex',
            }}
          >
            <Button onClick={handleClose} sx={{ mr: 2, color: '#984A4A' }}>
              Sair
            </Button>
            <Button onClick={() => setOpenNewComunicacao(true)} sx={{ mr: 2, color: '#009D81' }}>
              Registrar nova resposta
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  )
}
