import * as React from 'react'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import DeleteIcon from '@mui/icons-material/Delete'
import VisibilityIcon from '@mui/icons-material/Visibility'

import { Box, FormControl, IconButton, MenuItem, Select, TablePagination } from '@mui/material'
import { Cell, ChipAlert, TableMain, Title } from './styled'
import ModalConfirm from '~components/modais/modalConfirm'
import ModalNfs from '~components/modais/modalNfs'
import ModalSendNfs from '~components/modais/modalSendNfs'
import moment from 'moment'
import DescriptionIcon from '@mui/icons-material/Description'
import { colors } from '~constants/colorsPallete'
import ModalCommentNfs from '~components/modais/modalCommentNfs'
import { CustomTable } from '../conversationsTable/styled'
import { override } from 'pages/PushNotificationPage'
import { MoonLoader } from 'react-spinners'
import { DocumentsTableProps } from './types'
import { cnpjMask } from '~utils/cnpjMask'

export default function NotasTable({ data, onFilterChange, count, loading }: DocumentsTableProps) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [open, setOpen] = React.useState(false)
  const [revisao, setRevisao] = React.useState('')
  const [openComment, setOpenComment] = React.useState(false)
  const [openNewComunicacao, setOpenNewComunicacao] = React.useState(false)
  const [idnfs, setIdnfs] = React.useState('')
  const [id, setId] = React.useState('')
  const [openValid, setOpenValid] = React.useState(false)
  const [isValid, setIsValid] = React.useState<any>()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleOpenValid = () => setOpenValid(true)
  const handleCloseValid = () => {
    setOpenValid(false)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  React.useEffect(() => {
    onFilterChange({ page: page + 1, per_page: rowsPerPage })
  }, [page, rowsPerPage])

  return (
    <>
      {loading ? (
        <Box alignContent={'center'} justifyContent={'center'} margin={'auto'}>
          <MoonLoader
            color={'#009d81'}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
            cssOverride={override}
          />
        </Box>
      ) : null}
      <ModalSendNfs isValid={isValid} handleClose={handleCloseValid} open={openValid} id={id} />
      <ModalCommentNfs
        id={idnfs}
        handleClose={() => setOpenComment(false)}
        handleOpen={() => setOpenComment(true)}
        open={openComment}
      />
      <ModalNfs handleClose={() => setOpenNewComunicacao(false)} open={openNewComunicacao} revisao={revisao} />
      <ModalConfirm
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
        typeDelete="/shared_api/v1/invoices/"
        id={idnfs}
      />
      <CustomTable>
        <Title>Todas as Notas Fiscais</Title>
        <TableMain>
          <TableHead>
            <TableRow>
              <Cell align="center">Razão social</Cell>
              <Cell align="center">CNPJ</Cell>
              {/* <Cell align="center">Status</Cell> */}
              <Cell align="center">Verificação</Cell>
              <Cell align="center">Data da solicitação</Cell>
              <Cell align="center">Data do recebimento</Cell>
              <Cell align="center">Status</Cell>
              <Cell align="center">Observações</Cell>
              <Cell align="center">Visualizar</Cell>
              <Cell align="center">Deletar</Cell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: any, index: number) => (
              <TableRow key={index} hover>
                <TableCell component="th" scope="row" align="center">
                  {row.attributes.corporate_name}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {row.attributes?.cnpj ? cnpjMask(row.attributes?.cnpj) : '--'}
                </TableCell>

                {/*  <TableCell component="th" scope="row" align="center">
                  {row.attributes.received_status === 'solicited' ? 'Solicitado' : 'Recebido'}
                </TableCell> */}

                <TableCell align="center">
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      disabled={
                        row.attributes.verification_status === 'pending' || row.attributes.invoice_date === null
                      }
                      defaultValue={
                        row.attributes.verification_status === 'pending'
                          ? 2
                          : row.attributes.verification_status === 'refused'
                          ? 1
                          : 0
                      }
                      label="Opções"
                      onChange={(e) => {
                        setIsValid(e.target.value)
                        setId(row.id)
                        if (e.target.value !== 2) handleOpenValid()
                      }}
                    >
                      <MenuItem value={2}>Verificar</MenuItem>
                      <MenuItem value={0}>Validada</MenuItem>
                      <MenuItem value={1}>Recusada</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {moment(row.attributes.created_at).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {row.attributes.invoice_date ? moment(row.attributes.invoice_date).format('DD/MM/YYYY') : '--'}
                </TableCell>

                <TableCell align="center">
                  {row.attributes.invoice_date ? (
                    <Box
                      minWidth={110}
                      bgcolor={colors.warn}
                      color="white"
                      borderRadius={100}
                      textAlign="center"
                      py="2px"
                      display={'inline-block'}
                    >
                      {'A pagar'}
                    </Box>
                  ) : (
                    <Box
                      minWidth={110}
                      bgcolor={colors.blue}
                      color="white"
                      borderRadius={100}
                      textAlign="center"
                      py="2px"
                      display={'inline-block'}
                    >
                      {'Solicitada'}
                    </Box>
                  )}
                </TableCell>

                <TableCell align="center">
                  <IconButton
                    disabled={row.attributes.verification_status === 'refused' ? false : true}
                    aria-label="delete"
                    onClick={() => {
                      setIdnfs(row.id)
                      setOpenComment(true)
                    }}
                  >
                    <DescriptionIcon
                      fontSize="small"
                      sx={{
                        color: row.attributes.verification_status === 'refused' ? colors.blue : colors['gray.200'],
                      }}
                    />
                  </IconButton>
                </TableCell>

                <TableCell align="center">
                  <IconButton
                    disabled={row.attributes.pdf_file_url === null}
                    aria-label="delete"
                    onClick={() => {
                      window.open(row.attributes.pdf_file_url, '_blank', '')
                    }}
                  >
                    <VisibilityIcon
                      fontSize="small"
                      sx={{
                        color: !row.attributes.pdf_file_url === null ? colors['green.default'] : colors['gray.200'],
                      }}
                    />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      setIdnfs(row.id)
                      handleOpen()
                    }}
                  >
                    <DeleteIcon fontSize="small" sx={{ color: '#E96338' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableMain>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          labelRowsPerPage="Itens por página"
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CustomTable>
    </>
  )
}
