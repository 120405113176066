import { Box, Grid } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import { useState, useEffect } from 'react'
import { useUserStore } from 'stores/users'
import { calculateRatings } from 'hooks/CalculateRatings'
import { GetHeaders, GetHomeInfo, GetHomeInvoices, GetUserPush, GetUserScores } from '~services/Home'
import DashboardFilters from './HomeFilters'
import { LoadingIndicator } from '~components/loading'
import HomeInfo from './HomeInfo'
import MiniDrawer from '~components/drawer'
import DashboardIcon from '@mui/icons-material/Dashboard'
import { colors } from '~constants/colorsPallete'
import Header from '~components/header'
import { SendSlackMessage } from '~services/Slack'
import { Score } from 'pages/RatingPage'
import { useLocation, useNavigate } from 'react-router-dom'

function Dashboard() {
  const { openDrawer } = useUserStore()
  const Uid = useUserStore((state) => state.phone)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)
  const [dashboardData, setDashboardData] = useState<any>()

  const [dashboardHeaders, setDashboardHeaders] = useState<any>()
  const [filters, setFilters] = useState<{ doctor: string; date: string; company: string }>({
    doctor: '',
    date: '',
    company: '',
  })
  const [pendingNfs, setPendingNfs] = useState<number>(0)
  const [data, setData] = useState<[]>([])
  const [npsData, setNpsData] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingHeader, setLoadingHeader] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const from = location.state?.from?.pathname || '/'
  const clearUser = useUserStore((state) => state.clearUser)
  const loggedIn = useUserStore((state) => state.setLogged)

  const handleFilterChange = (newFilters: { doctor: string; date: string; company: string }) => {
    setFilters(newFilters)
  }

  const filterLatestRatings = (data: Score[]): Score[] => {
    const latestRatings: { [key: string]: Score } = {}

    data.forEach((score) => {
      const userHashId = score.attributes.user_hashid
      const createdAt = score.attributes.created_at

      if (!latestRatings[userHashId] || createdAt > latestRatings[userHashId].attributes.created_at) {
        latestRatings[userHashId] = score
      }
    })

    const sortedData = Object.values(latestRatings).sort((a, b) => a.attributes.rating - b.attributes.rating)

    return sortedData
  }

  const fetchData = () => {
    setLoading(true)
    setLoadingHeader(true)

    const params: Record<string, string> = {}

    if (filters.doctor) {
      params['doctor_names[]'] = filters.doctor
    }

    if (filters.date) {
      params['due_date'] = filters.date
    }

    if (filters.company) {
      params['institution_company_hashids[]'] = filters.company
    }
    setData([])

    GetHomeInvoices(Uid, access, client)
      .then((response) => {
        setPendingNfs(response.data.meta.count)
        GetUserScores(Uid, access, client).then((response) => {
          const filteredData = filterLatestRatings(response.data.data)
          const ratingsArray = calculateRatings(filteredData)
          setNpsData(ratingsArray)
          GetUserPush(Uid, access, client)
            .then((response) => {
              setData(response.data.data)
              GetHomeInfo(Uid, access, client, params)
                .then((response) => {
                  setDashboardData(response.data)
                  GetHeaders(Uid, access, client, params)
                    .then((response) => {
                      setDashboardHeaders(response.data)
                      setLoadingHeader(false)
                    })
                    .catch((error) => {
                      if (error.request.status === 401) {
                        loggedIn(false)
                        clearUser()
                        navigate(from, { replace: true })
                      }
                      toast.error('Ooops! Algo deu errado ao carregar o Header, tente novamente!')
                      SendSlackMessage('/institution_api/v1/statistics/institution_headers', error, params, Uid, 'Get')
                      setLoadingHeader(false)
                    })
                  setLoading(false)
                })
                .catch((error) => {
                  setLoading(false)
                  toast.error('Ooops! Algo deu errado ao carregar a Home, tente novamente!')
                  SendSlackMessage('/institution_api/v1/statistics/institution_dashboard', error, params, Uid, 'Get')
                  console.log(error)
                })
            })
            .catch((error) => {
              SendSlackMessage('/institution_api/v1/push_notifications/?per_page=100', error, params, Uid, 'Get')
            })
        })
      })
      .catch((error) => {
        if (error.request.status === 401) {
          loggedIn(false)
          clearUser()
          navigate(from, { replace: true })
        }
      })
  }

  useEffect(() => {
    fetchData()
  }, [filters])

  return (
    <Box sx={{ backgroundColor: colors.bg }}>
      <Grid container spacing={2} p={2} columns={16}>
        <Grid item xs={2} sm={openDrawer ? 3 : 1} md={openDrawer ? 3 : 1}>
          <MiniDrawer />
        </Grid>
        <Grid item xs={14} sm={openDrawer ? 13 : 15} md={openDrawer ? 13 : 15}>
          <Header text="Dashboard" icon={<DashboardIcon sx={{ mt: '4px', mr: 2, color: colors['gray.500'] }} />} />
          <DashboardFilters onFilterChange={handleFilterChange} />
          {loading ? (
            <LoadingIndicator />
          ) : (
            <HomeInfo
              loadingHeader={loadingHeader}
              headers={dashboardHeaders}
              date={filters.date}
              dashboardData={dashboardData}
              pendingNfs={pendingNfs}
              loading={loading}
              pushData={data}
              npsData={npsData}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default Dashboard
