import { Box, Grid } from '@mui/material'

import { ToastContainer, toast } from 'react-toastify'
import NotasTable from '~components/table/notasTable'
import React, { useEffect, useState } from 'react'
import ModalAddNewNfs from '~components/modais/modalAddNewNfs'
import { CustomButton } from '~utils/styles'
import AddIcon from '@mui/icons-material/Add'
import { useUserStore } from 'stores/users'
import 'dayjs/locale/pt-br'
import ReceiptIcon from '@mui/icons-material/Receipt'
import { colors } from '~constants/colorsPallete'
import MiniDrawer from '~components/drawer'
import Header from '~components/header'
import { SendSlackMessage } from '~services/Slack'
import { GetFilteredNfs } from '~services/Receipts'
import { GetUsers } from '~services/User'
import { useLocation, useNavigate } from 'react-router-dom'
import ReceiptsFilters from './filters'

function Nfs() {
  const [pageInfo, setPageInfo] = useState({ page: '1', per_page: '10' })
  const [count, setCount] = React.useState<number>(0)
  const [open, setOpen] = React.useState(false)
  const [isCreate, setIsCreate] = React.useState(false)
  const [loading, setLoading] = useState(false)
  const Uid = useUserStore((state) => state.phone)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)
  const [data, setData] = useState<[]>([])
  const { openDrawer } = useUserStore()
  const location = useLocation()
  const navigate = useNavigate()
  const from = location.state?.from?.pathname || '/'
  const clearUser = useUserStore((state) => state.clearUser)
  const loggedIn = useUserStore((state) => state.setLogged)
  const [filterMedicOptions, setFilterMedicOptions] = useState<any>()
  const params: Record<string, string> = {}
  const [filters, setFilters] = useState<{
    date: string
    endDate: string
    status: string
  }>({
    date: '',
    endDate: '',
    status: '',
  })

  const handleFilterChange = (newFilters: { date: string; endDate: string; status: string }) => {
    setFilters(newFilters)
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const fetchData = (pageInfo: { per_page: string; page: string }) => {
    setLoading(true)
    GetUsers(Uid, access, client)
      .then((response) => {
        setFilterMedicOptions(response.data.data)
      })
      .catch((error) => {
        SendSlackMessage('/institution_api/v1/users', error, null, Uid, 'Get')
        console.log(error)
      })

    if (filters.status) {
      params['exactly_search_keys[]'] = 'received_status'
      params['exactly_search_values[]'] = filters?.status
    }

    if (filters.date) {
      params['between_search_keys[]'] = 'invoice_date'
      params['between_search_values[]'] = filters.date
      params['between_search_values[] '] = filters.endDate
    }

    if (filters.endDate) {
      params['between_search_keys[]'] = 'invoice_date'
      params['between_search_values[]'] = filters.date
      params['between_search_values[] '] = filters.endDate
    }

    setData([])

    GetFilteredNfs(Uid, access, client, pageInfo, params)
      .then((response) => {
        setData(response.data.data)
        setCount(response.data.meta.count)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
        if (error.request?.status === 401) {
          loggedIn(false)
          clearUser()
          navigate(from, { replace: true })
        }
        toast.error('Ooops! Algo deu errado, tente novamente!')
        SendSlackMessage(
          `/shared_api/v1/invoices/?per_page=${pageInfo.per_page}&page=${pageInfo.page}`,
          error,
          params,
          Uid,
          'Get',
        )
      })
  }

  useEffect(() => {
    fetchData(pageInfo)
  }, [filters])

  const handlePageChange = (pageInfo: { page: string; per_page: string }) => {
    fetchData(pageInfo)
  }

  return (
    <>
      <ModalAddNewNfs
        isCreate={isCreate}
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
        isDoc={false}
        doctors={filterMedicOptions}
      />
      <Box sx={{ backgroundColor: colors.bg }}>
        <ToastContainer />
        <Grid container spacing={2} p={2} columns={16}>
          <Grid item xs={2} sm={openDrawer ? 3 : 1} md={openDrawer ? 3 : 1}>
            <MiniDrawer />
          </Grid>
          <Grid item xs={14} sm={openDrawer ? 13 : 15} md={openDrawer ? 13 : 15}>
            <Header text="Notas Fiscais" icon={<ReceiptIcon sx={{ mt: '4px', mr: 2, color: colors['gray.500'] }} />} />
            <ReceiptsFilters onFilterChange={handleFilterChange} />
            <NotasTable data={data} loading={loading} onFilterChange={handlePageChange} count={count} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Nfs
