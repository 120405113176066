import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, Button, Checkbox, FormControl, Grid, TextField } from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import dayjs from 'dayjs'
import { icon, checkedIcon } from '~utils/styles'
import { GetAllCompanys, GetAllUsers } from '~services/Home'
import { useUserStore } from 'stores/users'
import { colors } from '~constants/colorsPallete'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'
import { SendSlackMessage } from '~services/Slack'

interface DashboardFiltersProps {
  onFilterChange: (filters: any) => void
}

const DashboardFilters: React.FC<DashboardFiltersProps> = ({ onFilterChange }) => {
  const [filterMedicOptions, setFilterMedicOptions] = useState<any[]>([])
  const [filterCompaniesOptions, setFilterCompaniesOptions] = useState<any[]>([])
  const [date, setDate] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [doctor, setDoctor] = useState<any>()
  const [company, setCompany] = useState<any>()
  const Uid = useUserStore((state) => state.phone)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)
  const selectedDoctorOption = filterMedicOptions.filter((option) => doctor?.includes(option))
  const selectedCompanieOption = filterCompaniesOptions.filter((option) => company?.includes(option.id))

  useEffect(() => {
    GetAllUsers(Uid, access, client)
      .then((response) => {
        const uniqueUsers = Array.from(new Set(response.data.map((user) => user))).map((id) =>
          response.data.find((user) => user === id),
        )
        setFilterMedicOptions(uniqueUsers)
      })
      .catch((error) => {
        SendSlackMessage('/institution_api/v1/doctor_names?per_page=500', error, null, Uid, 'Get')
      })
    GetAllCompanys(Uid, access, client)
      .then((response) => {
        setFilterCompaniesOptions(response.data.data)
      })
      .catch((error) => {
        SendSlackMessage('/institution_api/v1/institution_companies?per_page=500', error, null, Uid, 'Get')
      })
  }, [Uid, access, client])

  const handleChangeDate = (event: any) => {
    const formattedDate = dayjs(event).format('YYYY/MM/DD')
    setDate(formattedDate)
  }

  return !isOpen ? (
    <Grid container direction="row" justifyContent="flex-end" mb={2}>
      <Button
        disableElevation
        variant="contained"
        endIcon={<FilterAltRoundedIcon />}
        sx={{ backgroundColor: colors['gray.500'], borderRadius: 100 }}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        Abrir Filtros
      </Button>
    </Grid>
  ) : (
    <>
      <Grid container direction="row" justifyContent="flex-end" mb={2}>
        <Grid item xs={12} display={'flex'} justifyContent={'flex-end'} mb={0} pb={0}>
          <Button
            disableElevation
            variant="contained"
            endIcon={<FilterAltRoundedIcon />}
            sx={{ backgroundColor: colors['gray.500'], borderRadius: 100 }}
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            Fechar Filtros
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={1}
        p={3}
        mb={2}
        border={'1px solid #E0E0E0'}
        borderRadius={'3px'}
        sx={{ backgroundColor: colors['white.300'] }}
      >
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
            <FormControl fullWidth>
              <DatePicker
                label="Mês de visualização"
                format="MM/YYYY"
                views={['month', 'year']}
                defaultValue={dayjs()}
                sx={{
                  backgroundColor: colors.white,
                  borderRadius: 2,
                  border: 0,
                  '& fieldset': { border: 'none' },
                }}
                onChange={(e) => {
                  setDate(dayjs(e).format('YYYY/MM/DD'))
                  handleChangeDate(dayjs(e).format('YYYY/MM/DD'))
                }}
              />
            </FormControl>
          </LocalizationProvider>
        </Grid>
        <Grid item>
          {filterMedicOptions && (
            <FormControl fullWidth>
              <Autocomplete
                multiple
                limitTags={4}
                id="checkboxes-tags-demo"
                options={['Selecionar Todos', ...filterMedicOptions]} // Adicione a opção de selecionar todos
                onChange={(event, newValue) => {
                  // Verifique se a opção de Selecionar Todos foi selecionada
                  if (newValue.some((item) => item === 'Selecionar Todos')) {
                    // Se já estava selecionada e foi clicada novamente, remova todos
                    if (selectedDoctorOption.length === filterMedicOptions.length) {
                      setDoctor([])
                    } else {
                      // Senão, selecione todos
                      setDoctor(filterMedicOptions.map((item) => item))
                    }
                  } else {
                    // Manipulação para seleção normal
                    setDoctor(newValue.map((item) => item))
                  }
                  setCompany(null)
                }}
                value={selectedDoctorOption}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={
                        selected ||
                        (option === 'Selecionar Todos' && selectedDoctorOption.length === filterMedicOptions.length)
                      }
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Médicos"
                    placeholder="Selecione os médicos"
                    sx={{
                      minWidth: '250px',
                      backgroundColor: colors.white,
                      borderRadius: 2,
                      border: 0,
                      '& fieldset': { border: 'none' },
                    }}
                  />
                )}
              />
            </FormControl>
          )}
        </Grid>
        <Grid item>
          {filterCompaniesOptions && (
            <FormControl fullWidth>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={filterCompaniesOptions}
                onChange={(event: any, newValue: any) => {
                  setCompany(newValue.map((item: { id: string }) => item.id))
                  setDoctor(null)
                }}
                sx={{ border: 0 }}
                value={selectedCompanieOption}
                disableCloseOnSelect
                getOptionLabel={(option: { attributes: { name: string } }) => option.attributes.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.attributes.name}
                  </li>
                )}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label="Hospitais"
                    placeholder="Hospitais"
                    sx={{
                      backgroundColor: colors.white,
                      minWidth: '200px',
                      borderRadius: 2,
                      border: 0,
                      '& fieldset': { border: 'none' },
                    }}
                  />
                )}
              />
            </FormControl>
          )}
        </Grid>

        <Grid item xl={12} sm={12} md={12} lg={12} xs={12}>
          <Box display="flex" flexDirection="row" alignContent={'center'} justifyContent={'flex-end'} mt={3}>
            <Button
              disableElevation
              variant="contained"
              sx={{ backgroundColor: colors.success, borderRadius: 100, mr: 2 }}
              onClick={() => {
                onFilterChange({ doctor, date, company })
              }}
            >
              Filtrar
            </Button>
            <Button
              disableElevation
              variant="outlined"
              sx={{ borderColor: colors.error, color: colors.error, borderRadius: 100 }}
              onClick={() => {
                setDate('')
                setCompany(null)
                setDoctor(null)
                onFilterChange({ doctor: '', date: '' })
              }}
            >
              Limpar filtros
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default DashboardFilters
