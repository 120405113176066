import * as React from 'react'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ChatIcon from '@mui/icons-material/Chat'

import { Box, IconButton, TablePagination, Typography } from '@mui/material'
import { Cell, CustomTable, TableMain, Title } from './styled'
import ModalConfirm from '~components/modais/modalConfirm'
import { useUserStore } from 'stores/users'
import ModalHistory from '~components/modais/modalHistory'
import { MoonLoader } from 'react-spinners'

import dayjs from 'dayjs'
import { colors } from '~constants/colorsPallete'
import { GetConversationData } from '~services/Conversations'
import { DocumentsTableProps, override } from './types'

export default function ConversationTable({ data, isCom, loading, onFilterChange, count }: DocumentsTableProps) {
  const [page, setPage] = React.useState(0)
  const [openHistory, setOpenHistory] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [open, setOpen] = React.useState(false)
  const [dataHistory, setDataHistory] = React.useState()
  const [id, setId] = React.useState()
  const Uid = useUserStore((state) => state.phone)
  const access = useUserStore((state) => state.token)
  const client = useUserStore((state) => state.client)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleHistoryClose = () => {
    setOpenHistory(false)
    window.location.reload()
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getMedicName = (participants: any) => {
    const filtered = participants.filter((participant: any) => participant.type === 'User')
    return filtered[0]?.name || 'Não informado'
  }

  React.useEffect(() => {
    onFilterChange({ page: page + 1, per_page: rowsPerPage })
  }, [page, rowsPerPage])

  const updateData = () => {
    GetConversationData(id, Uid, access, client)
      .then((response) => {
        /*  setOpenHistory(true) */
        setDataHistory(response.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <Box>
      <ModalHistory
        handleClose={() => handleHistoryClose()}
        open={openHistory}
        data={dataHistory}
        updateData={updateData}
      />
      {loading ? (
        <Box alignContent={'center'} justifyContent={'center'} p={2}>
          <MoonLoader
            color={'#009d81'}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
            cssOverride={override}
          />
        </Box>
      ) : null}
      <ModalConfirm handleOpen={handleOpen} handleClose={handleClose} open={open} />
      {data && (
        <CustomTable>
          <Title>Todos os chamados</Title>
          <TableMain>
            <TableHead>
              <TableRow>
                <Cell align="center">Médico</Cell>
                <Cell align="center">Paciente</Cell>
                <Cell align="center">Procedimento</Cell>
                <Cell align="center">Assunto</Cell>
                <Cell align="center">Data de abertura</Cell>
                <Cell align="center">Status</Cell>
                <Cell align="center">{isCom ? 'Comunicação' : 'Visualizar'}</Cell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row: any, index: number) => {
                return (
                  <TableRow key={index} hover>
                    <TableCell component="th" scope="row" align="center">
                      {getMedicName(row?.attributes?.participants)}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {row?.attributes?.patient || 'Não informado'}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {row?.attributes?.procedure || 'Não vinculado'}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {row?.attributes?.conversation_subject || 'Não informado'}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {dayjs(row?.attributes?.created_at, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <Box
                        minWidth={110}
                        bgcolor={row?.attributes?.status === 'closed' ? colors['green.default'] : colors.error}
                        color="white"
                        borderRadius={100}
                        textAlign="center"
                        py="2px"
                        display={'inline-block'}
                      >
                        {row?.attributes?.status === 'open'
                          ? 'Aberta'
                          : row?.attributes?.status === 'closed'
                          ? 'Fechada'
                          : 'Pendente'}
                      </Box>
                    </TableCell>
                    {isCom ? (
                      <TableCell
                        align="center"
                        onClick={() => {
                          setId(row.id)
                          GetConversationData(row.id, Uid, access, client)
                            .then((response) => {
                              setOpenHistory(true)
                              setDataHistory(response.data.data)
                            })
                            .catch((error) => {
                              console.log(error)
                            })
                        }}
                      >
                        <IconButton aria-label="delete">
                          <ChatIcon
                            fontSize="small"
                            sx={{
                              color: row.id ? colors.error : colors['gray.400'],
                            }}
                          />
                        </IconButton>
                      </TableCell>
                    ) : (
                      <TableCell align="center">
                        <IconButton aria-label="delete">
                          <VisibilityIcon fontSize="small" sx={{ color: '#009d81' }} />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                )
              })}
            </TableBody>
          </TableMain>
          <TablePagination
            labelRowsPerPage="Itens por página"
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CustomTable>
      )}
    </Box>
  )
}
